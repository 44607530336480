<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import AuthSvc from '@/services/auth'
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// import { byPrefixAndName } from '@awesome.me/kit-KIT_CODE/icons'

import SidenavItem from './SidenavItem.vue'
// import SidenavCard from "./SidenavCard.vue";

const store = useStore()
const isRTL = computed(() => store.state.isRTL)
const isPlatformAdministrator = AuthSvc.getIsPlatformAdministrator()

const getRoute = () => {
  const route = useRoute()
  const routeArr = route.path.split('/')
  return routeArr[1]
}
</script>

<template>
  <div id="sidenav-collapse-main" class="collapse navbar-collapse w-auto h-auto h-100">
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-item
          to="/dashboard"
          :class="getRoute() === 'dashboard' ? 'active' : ''"
          :navText="isRTL ? 'لوحة القيادة' : 'Dashboard'"
        >
          <template v-slot:icon>
            <i class="ni ni-tv-2 text-primary text-m opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li v-if="!isPlatformAdministrator" class="nav-item">
        <sidenav-item
          to="/reservations"
          :class="getRoute() === 'reservations' ? 'active' : ''"
          :navText="isRTL ? 'الجداول' : 'Reservations'"
        >
          <template v-slot:icon>
            <i class="ni ni-calendar-grid-58 text-warning text-m opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li v-if="!isPlatformAdministrator" class="nav-item">
        <sidenav-item
          to="/roomTypes"
          :class="getRoute() === 'roomTypes' ? 'active' : ''"
          :navText="isRTL ? 'الجداول' : 'Room Types'"
        >
          <template v-slot:icon>
            <i class="ni ni-single-copy-04 text-success text-m opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li v-if="!isPlatformAdministrator" class="nav-item">
        <sidenav-item
          to="/rooms"
          :class="getRoute() === 'rooms' ? 'active' : ''"
          :navText="isRTL ? 'الجداول' : 'Rooms'"
        >
          <template v-slot:icon>
            <i class="ni ni-shop text-info text-m opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/users"
          :class="getRoute() === 'users' ? 'active' : ''"
          :navText="isRTL ? 'الجداول' : 'Users'"
        >
          <template v-slot:icon>
            <!-- <i
              class="ni ni-calendar-grid-58 text-warning text-sm opacity-10"
            ></i> -->
            <!-- <font-awesome-icon icon="fa-solid fa-coffee" size="xs" /> -->
            <i style="color: #f5bc59" class="fa-solid fa-users text-m opacity-10"></i>
            <!-- <i class="ni ni-single-02 text-dark text-sm opacity-10"></i> -->
          </template>
        </sidenav-item>
      </li>

      <li v-if="!isPlatformAdministrator" class="nav-item">
        <sidenav-item
          to="/menuItems"
          :class="getRoute() === 'menuItems' ? 'active' : ''"
          :navText="isRTL ? 'الجداول' : 'Menu Items'"
        >
          <template v-slot:icon>
            <i style="color: #198754" class="fa-solid fa-utensils text-m opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li v-if="!isPlatformAdministrator" class="nav-item">
        <sidenav-item
          to="/fnbInvoices"
          :class="getRoute() === 'FNB Invoices' ? 'active' : ''"
          :navText="isRTL ? 'الجداول' : 'FNB Invoices'"
        >
          <template v-slot:icon>
            <i style="color: grey" class="fa-regular fa-credit-card text-m opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li v-if="!isPlatformAdministrator" class="nav-item">
        <sidenav-item
          to="/staffDetails"
          :class="getRoute() === 'Staff Details' ? 'active' : ''"
          :navText="isRTL ? 'الجداول' : 'Staff Details'"
        >
          <template v-slot:icon>
            <i class="fa-solid fa-people-group text-m text-dark opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li v-if="!isPlatformAdministrator" class="nav-item">
        <sidenav-item
          to="/staffAttendance"
          :class="getRoute() === 'Staff Attendance' ? 'active' : ''"
          :navText="isRTL ? 'الجداول' : 'Staff Attendance'"
        >
          <template v-slot:icon>
            <i class="fa-regular fa-calendar-check text-warning text-m opacity-10"></i>
            <!-- <i
              class="fa-solid fa-business-time text-m text-warning text-dark opacity-10"
            ></i> -->
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/subscriptions"
          :class="getRoute() === 'Subscriptions' ? 'active' : ''"
          :navText="isRTL ? 'الجداول' : 'Subscriptions'"
        >
          <template v-slot:icon>
            <i class="fa-solid fa-arrows-rotate text-danger text-m opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/hotelDetails"
          :class="getRoute() === 'Hotel Details' ? 'active' : ''"
          :navText="isRTL ? 'الجداول' : 'Hotel Details'"
        >
          <template v-slot:icon>
            <i style="color: #20c997" class="fa-solid fa-house-chimney text-m opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <!-- <li class="nav-item">
        <sidenav-item
          to="/billing"
          :class="getRoute() === 'billing' ? 'active' : ''"
          :navText="isRTL ? 'الفواتیر' : 'Billing'"
        >
          <template v-slot:icon>
            <i class="ni ni-credit-card text-success text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li> -->

      <!-- <li class="nav-item">
        <sidenav-item
          to="/virtual-reality"
          :class="getRoute() === 'virtual-reality' ? 'active' : ''"
          :navText="isRTL ? 'الواقع الافتراضي' : 'Virtual Reality'"
        >
          <template v-slot:icon>
            <i class="ni ni-app text-info text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li> -->

      <!-- <li class="nav-item">
        <sidenav-item
          to="/rtl-page"
          :class="getRoute() === 'rtl-page' ? 'active' : ''"
          navText="RTL"
        >
          <template v-slot:icon>
            <i class="ni ni-world-2 text-danger text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li> -->
      <li class="nav-item">
        <sidenav-item
          to="/profile"
          :class="getRoute() === 'profile' ? 'active' : ''"
          :navText="isRTL ? 'حساب تعريفي' : 'Profile'"
        >
          <template v-slot:icon>
            <!-- <i class="fa-solid fa-circle-user"></i> -->
            <i class="fa-solid fa-circle-user text-dark text-m opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <!-- <li class="nav-item">
        <sidenav-item
          to="/signin"
          :class="getRoute() === 'signin' ? 'active' : ''"
          :navText="isRTL ? 'تسجيل الدخول' : 'Sign In'"
        >
          <template v-slot:icon>
            <i class="ni ni-single-copy-04 text-danger text-m opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/signup"
          :class="getRoute() === 'signup' ? 'active' : ''"
          :navText="isRTL ? 'اشتراك' : 'Sign Up'"
        >
          <template v-slot:icon>
            <i class="ni ni-collection text-info text-m opacity-10"></i>
          </template>
        </sidenav-item>
      </li> -->
    </ul>
  </div>
</template>
