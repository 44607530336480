import { createRouter, createWebHistory } from 'vue-router'
import _ from 'lodash'
import AuthSvc from '@/services/auth'
import LocalStorageSvc from '@/services/localStorage'

import Dashboard from '../views/Dashboard.vue'
import Tables from '../views/Tables.vue'
import Billing from '../views/Billing.vue'
import VirtualReality from '../views/VirtualReality.vue'
import RTL from '../views/Rtl.vue'
import Profile from '../views/Profile.vue'
import Signup from '../views/Signup.vue'
import Signin from '../views/Signin.vue'
import Rooms from '../views/Rooms.vue'
import RoomTypes from '../views/RoomTypes.vue'
import Reservations from '../views/Reservations.vue'
import MenuItems from '../views/MenuItems.vue'
import FNBInvoices from '../views/FNBInvoices.vue'
import StaffDetails from '../views/StaffDetails.vue'
import Subscriptions from '../views/Subscriptions.vue'
import HotelDetails from '../views/HotelDetails.vue'
import StaffAttendance from '../views/StaffAttendance.vue'
import LandingPage from '../views/LandingPage.vue'

const routes = [
  {
    path: '/',
    name: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: LandingPage
  },
  {
    path: '/signin',
    name: 'Signin',
    component: Signin
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: { requiresAuth: true },
    component: Dashboard
  },
  {
    path: '/users',
    name: 'Users',
    meta: { requiresAuth: true },
    component: Tables
  },
  {
    path: '/rooms',
    name: 'Rooms',
    meta: { requiresAuth: true },
    component: Rooms
  },
  {
    path: '/roomTypes',
    name: 'Room Types',
    meta: { requiresAuth: true },
    component: RoomTypes
  },
  {
    path: '/menuItems',
    name: 'Menu Items',
    meta: { requiresAuth: true },
    component: MenuItems
  },
  {
    path: '/reservations',
    name: 'Reservations',
    meta: { requiresAuth: true },
    component: Reservations
  },
  {
    path: '/fnbInvoices',
    name: 'FNB Invoices',
    meta: { requiresAuth: true },
    component: FNBInvoices
  },
  {
    path: '/billing',
    name: 'Billing',
    meta: { requiresAuth: true },
    component: Billing
  },
  {
    path: '/virtual-reality',
    name: 'Virtual Reality',
    meta: { requiresAuth: true },
    component: VirtualReality
  },
  {
    path: '/rtl-page',
    name: 'RTL',
    component: RTL
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: { requiresAuth: true },
    component: Profile
  },
  {
    path: '/staffDetails',
    name: 'Staff Details',
    meta: { requiresAuth: true },
    component: StaffDetails
  },
  {
    path: '/staffAttendance',
    name: 'Staff Attendance',
    meta: { requiresAuth: true },
    component: StaffAttendance
  },
  {
    path: '/subscriptions',
    name: 'Subscriptions',
    meta: { requiresAuth: true },
    component: Subscriptions
  },
  {
    path: '/hotelDetails',
    name: 'Hotel Details',
    meta: { requiresAuth: true },
    component: HotelDetails
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active'
})

router.beforeEach((to, from, next) => {
  const token = AuthSvc.getJWTToken()
  if (to.path === '/' || to.path === '/home') {
    const jwtExpiry = AuthSvc.getTokenExpiry()
    const currentTime = Date.now().valueOf() / 1000
    if (!_.isEmpty(token) && jwtExpiry > currentTime) {
      router.push('/dashboard')
    } else {
      LocalStorageSvc.clear()
      next()
    }
  } else if (to.meta?.requiresAuth && _.isEmpty(token)) {
    router.push('/home')
  } else {
    next()
  }
})

export default router
