<template>
  <div class="card">
    <div class="card-header px-2 pb-0">
      <el-row>
        <el-col :span="12">
          <el-input
            v-model="base.search.text"
            placeholder="Please input"
            clearable
            class="input-with-select"
            @keyup.enter="onSearch"
          >
            <template #prepend>
              <el-select v-model="base.search.key" placeholder="Select" style="width: 125px">
                <el-option label="Room Type" value="name" />
                <el-option label="Bed Type" value="bedType" />
                <!-- <el-option label="Number Of Adults" value="numberOfAdults" />
                <el-option label="Number Of Adults" value="numberOfAdults" />
                <el-option label="Number Of Extra Adults" value="numberOfExtraAdults" /> -->
              </el-select>
            </template>
            <template #append>
              <el-button :icon="Search" @click="onSearch" />
            </template>
          </el-input>
        </el-col>
        <el-col :span="12" class="pr20">
          <el-button
            class="text-uppercase ms-auto fr"
            type="primary"
            :icon="Plus"
            @click="handleAddRoomType"
          >
            Room
          </el-button>
        </el-col>
      </el-row>
    </div>
    <div class="card-body px-0 pt-0 pb-2 mt-2">
      <el-table
        ref="tableRef"
        class="roomtypestable-max-height"
        row-key="email"
        header-cell-class-name="text-uppercase text-secondary text-xs font-weight-bolder opacity-7"
        row-class-name="text-secondary text-xs font-weight-bold"
        :data="base.data.records"
        :stripe="true"
        :scrollbar-always-on="true"
        style="width: 100%"
      >
        <el-table-column prop="name" label="Room Type" />
        <el-table-column prop="withAC" label="With AC?">
          <template #default="scope">
            <span v-if="scope.row.withAC" class="badge bg-gradient-success">Yes</span>
            <span v-else class="badge bg-gradient-secondary">No</span>
          </template>
        </el-table-column>
        <el-table-column prop="bedType" label="Bed Type" />
        <el-table-column prop="numberOfAdults" label="Number Of Adults" />
        <el-table-column prop="numberOfExtraAdults" label="Number Of Extra Beds" />
        <el-table-column prop="ratePerNight" label="Rate Per Night" />
        <el-table-column prop="chargePerExtraAdult" label="Charge Per Extra Adult" />
        <el-table-column prop="taxPercentage" label="Tax Percentage" />
        <el-table-column label="" min-width="75">
          <template #default="scope">
            <el-button link type="primary" size="small" @click="handleEditClick(scope.row)">
              Edit
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="card-body px-0 pt-0 pb-2 mt-2">
      <el-pagination
        :current-page="base.data.cursor.currentPage"
        small
        class="m-t-10 mb2 ml10"
        layout="total, sizes, prev, pager, next, jumper"
        :page-size="base.data.cursor.perPage"
        :page-sizes="base.pageSizes"
        :total="parseInt(base.data.cursor.totalRecords)"
        @size-change="handlePageSizeChange"
        @current-change="handleCurrentPageChange"
      ></el-pagination>
    </div>
    <el-dialog
      v-model="base.roomTypeFormVisible"
      title="Room Type Information"
      width="70%"
      destroy-on-close
      align-center
    >
      <el-form
        ref="roomTypeRef"
        :model="base.roomTypeForm"
        label-position="top"
        :rules="base.roomTypeRules"
      >
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="Room Type" prop="name">
              <el-input v-model="base.roomTypeForm.name" size="large" autocomplete="off" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Bed Type" prop="bedType">
              <el-input v-model="base.roomTypeForm.bedType" size="large" autocomplete="off" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Number Of Adults" prop="numberOfAdults">
              <el-input-number
                v-model="base.roomTypeForm.numberOfAdults"
                :min="1"
                :max="3"
                class="w-100"
                controls-position="right"
                size="large"
              />
            </el-form-item>
          </el-col>
          <!-- <el-col :span="8">
            <el-form-item label="Number Of Child" prop="numberOfChild">
              <el-input-number
                v-model="base.roomTypeForm.numberOfChild"
                :min="1"
                class="w-100"
                controls-position="right"
                size="large"
              />
            </el-form-item>
          </el-col> -->
          <el-col :span="8">
            <el-form-item label="Allowed Extra Beds">
              <el-input-number
                v-model="base.roomTypeForm.numberOfExtraAdults"
                :min="0"
                :max="2"
                class="w-100"
                controls-position="right"
                size="large"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Rate Per Night" prop="ratePerNight">
              <el-input-number
                v-model="base.roomTypeForm.ratePerNight"
                class="w-100"
                :controls="false"
                size="large"
                @change="handleRatePerNightChange"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Charge Per Extra Adult">
              <el-input-number
                v-model="base.roomTypeForm.chargePerExtraAdult"
                class="w-100"
                :controls="false"
                size="large"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Tax Percentage">
              <el-input-number
                v-model="base.roomTypeForm.taxPercentage"
                class="w-100"
                :controls="false"
                size="large"
                disabled
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="With AC?">
              <el-switch
                v-model="base.roomTypeForm.withAC"
                size="large"
                active-text="Yes"
                inactive-text="No"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="base.roomTypeFormVisible = false">Cancel</el-button>
          <el-button type="primary" @click="handleSave">Confirm</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { Search, Plus } from '@element-plus/icons-vue'
import RoomTypeSvc from '@/services/roomType'
import AuthSvc from '@/services/auth'
import common from '@/utils/common'
import { ElNotification } from 'element-plus'
import constants from '@/constants'
import _ from 'lodash'

export default {
  name: 'RoomTypesTable',
  setup() {
    const roomTypeRef = ref(null)
    const base = reactive({
      roomTypeFormVisible: false,
      search: {
        text: '',
        key: 'name'
      },
      roomTypeForm: {
        name: 'Suite',
        bedType: 'double',
        numberOfAdults: 2,
        numberOfChild: 1,
        numberOfExtraAdults: 1,
        withAC: true,
        ratePerNight: 5500,
        chargePerExtraAdult: 100,
        taxPercentage: 18
      },
      data: {
        records: [],
        cursor: { currentPage: 0, perPage: 0, totalRecords: 0 }
      },
      roomTypeRules: {
        name: [{ required: true, message: ' ', trigger: 'blur' }],
        bedType: [{ required: true, message: ' ', trigger: 'blur' }],
        numberOfAdults: [{ required: true, message: ' ', trigger: 'blur' }],
        numberOfChild: [{ required: true, message: ' ', trigger: 'blur' }],
        ratePerNight: [{ required: true, message: ' ', trigger: 'blur' }]
      },
      pageSizes: constants.DEFAULT_PAGE_SIZES
    })

    const local = {
      currentQuery: undefined,
      sort: {},
      fields: undefined
    }

    onMounted(() => {
      fetchRoomTypes()
    })

    const onSearch = () => {
      let q = local.currentQuery
      if (base.search.text) {
        q = {}
        local.currentPage = 1
        // if (searchKeyType === 'date') {
        //   q[searchKey] = {
        //     $gte: QueryBuilder.getDate(searchText, 1),
        //     $lt: QueryBuilder.getDate(searchText, 2)
        //   }
        // } else {
        //   q[searchKey] = { $regex: '.*' + searchText + '.*', $options: 'i' }
        // }
        q[base.search.key] = {
          $regex: '.*' + base.search.text + '.*',
          $options: 'i'
        }
      } else {
        q = {}
      }
      if (!_.isEqual(q, local.currentQuery)) {
        local.currentQuery = q
        fetchRoomTypes(
          local.currentQuery,
          local.fields,
          base.data.cursor.currentPage,
          base.data.cursor.perPage,
          local.sort
        )
      }
    }

    const fetchRoomTypes = (query, projection, page, limit, sort) => {
      const options = AuthSvc.getOptions()
      RoomTypeSvc.find(options, query, projection, page, limit, sort)
        .then(response => {
          base.data = response.data
        })
        .catch(error => {
          console.log('RoomsTypesTable fetchRoomTypes error => ', error)
          common.showErrors(error)
        })
    }

    const createNewRoomType = () => {
      return {
        name: '',
        bedType: '',
        numberOfAdults: 2,
        numberOfExtraAdults: 0,
        numberOfChild: 0,
        withAC: true,
        ratePerNight: 0,
        chargePerExtraAdult: 0,
        taxPercentage: 12
      }
    }

    const handleAddRoomType = () => {
      base.roomTypeForm = createNewRoomType()
      base.roomTypeFormVisible = true
    }

    const handleEditClick = room => {
      const options = AuthSvc.getOptions()
      RoomTypeSvc.findById(options, room._id)
        .then(response => {
          base.roomTypeForm = response.data
          base.roomTypeFormVisible = true
        })
        .catch(error => {
          console.log('RoomsTypesTable handleEditClick error => ', error)
          common.showErrors(error)
        })
    }

    const handleSave = () => {
      roomTypeRef.value.validate(valid => {
        if (valid) {
          const options = AuthSvc.getOptions()
          RoomTypeSvc.save(options, base.roomTypeForm)
            .then(response => {
              const message =
                response.status === 201
                  ? 'Room Type created successfully'
                  : 'Room Type updated successfully'
              ElNotification({
                message,
                type: 'success'
              })
              base.roomTypeFormVisible = false
              fetchRoomTypes()
            })
            .catch(error => {
              console.log('RoomsTypesTable handleSave error => ', error)
              common.showErrors(error)
            })
        } else {
          common.showMandatoryValidation()
        }
      })
    }

    const handleCurrentPageChange = val => {
      base.data.cursor.currentPage = val
      fetchRoomTypes(
        local.currentQuery,
        local.fields,
        base.data.cursor.currentPage,
        base.data.cursor.perPage,
        local.sort
      )
    }

    const handlePageSizeChange = val => {
      base.data.cursor.perPage = val
      fetchRoomTypes(
        local.currentQuery,
        local.fields,
        base.data.cursor.currentPage,
        base.data.cursor.perPage,
        local.sort
      )
    }

    const handleRatePerNightChange = () => {
      base.roomTypeForm.taxPercentage = base.roomTypeForm.ratePerNight > 3000 ? 18 : 12
    }

    return {
      base,
      roomTypeRef,
      Search,
      Plus,
      handleEditClick,
      handleAddRoomType,
      handleSave,
      handleCurrentPageChange,
      handlePageSizeChange,
      onSearch,
      handleRatePerNightChange
    }
  }
}
</script>

<style>
.roomtypestable-max-height {
  height: calc(100vh - 205px) !important;
}
</style>
