'use strict'
const storage = window.localStorage

class LocalStorage {
  static setItem(code, value) {
    storage.setItem(code, value)
  }

  static getItem(code) {
    return storage.getItem(code) || ''
  }

  static removeItem(code) {
    storage.removeItem(code)
  }

  static clear() {
    storage.clear()
  }
}

export default LocalStorage
