<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const isNavFixed = computed(() => store.state.isNavFixed)

defineProps({
  currentPage: {
    type: String,
    default: ''
  },
  currentDirectory: {
    type: String,
    default: ''
  }
})
</script>

<template>
  <nav aria-label="breadcrumb">
    <h6 class="mb-0 font-weight-bolder" :class="isNavFixed ? 'text-dark' : 'text-white'">
      {{ currentPage }}
    </h6>
  </nav>
</template>
