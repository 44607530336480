<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const isRTL = computed(() => store.state.isRTL)
const sidebarMinimize = () => store.commit('sidebarMinimize')

const minimizeSidebar = () => {
  if (window.innerWidth < 1200) {
    sidebarMinimize()
  }
}

defineProps({
  to: {
    type: String,
    required: true
  },
  navText: {
    type: String,
    required: true
  }
})
</script>

<template>
  <router-link :to="to" class="nav-link" @click="minimizeSidebar">
    <div
      class="icon icon-shape icon-sm sidebar-icon text-center d-flex align-items-center justify-content-center"
    >
      <slot name="icon"></slot>
    </div>
    <span class="nav-link-text" :class="isRTL ? ' me-1' : 'ms-1'">{{ navText }}</span>
  </router-link>
</template>

<style>
.sidebar-icon i {
  top: 0px !important;
}
</style>
