<script>
import { onBeforeMount, onMounted, reactive } from 'vue'
import calendarUtil from '@/utils/calendarUtil'
import MiniStatisticsCard from '@/examples/Cards/MiniStatisticsCard.vue'
import GradientLineChart from '@/examples/Charts/GradientLineChart.vue'
import DashboardSvc from '@/services/dashboard'
import AuthSvc from '@/services/auth'
import common from '@/utils/common'

// import Carousel from "./components/Carousel.vue";
// import CategoriesList from "./components/CategoriesList.vue";

// import US from "@/assets/img/icons/flags/US.png";
// import DE from "@/assets/img/icons/flags/DE.png";
// import GB from "@/assets/img/icons/flags/GB.png";
// import BR from "@/assets/img/icons/flags/BR.png";

export default {
  name: 'Dashboard',
  components: {
    MiniStatisticsCard,
    GradientLineChart
  },
  setup() {
    const base = reactive({
      dates: [],
      isLoaded: false,
      summaryData: [], // last 7 days
      todayData: {
        revenueAmount: '0',
        roomDetails: {},
        staffAttendanceDetails: {}
      },
      yesterdayData: {
        revenueAmount: '0',
        roomDetails: {},
        staffAttendanceDetails: {}
      },
      revenuePercentage: '0%',
      occupancyRoomsPercentage: '0%',
      availableRoomsPercentage: '0%',
      weeklyRevenueAmount: [],
      monthlyRevenueAmount: [],
      isPlatformAdministrator: AuthSvc.getIsPlatformAdministrator(),
      platformAdminSummary: {}
    })

    onBeforeMount(() => {
      base.dates = calendarUtil.getLast7Dates()
    })

    onMounted(() => {
      if (base.isPlatformAdministrator) {
        fetchPlatformAdminSummaries()
      } else {
        fetchSummaries()
      }
    })

    const handleRevenueDescription = () => {
      // eslint-disable-next-line quotes
      return !base.revenuePercentage.startsWith('-')
        ? `<span class='text-sm font-weight-bolder text-green'>${base.revenuePercentage} </span>since yesterday`
        : `<span class='text-sm font-weight-bolder text-danger'>${base.revenuePercentage} </span>since yesterday`
    }

    const handleOccupancyRoomsDescription = () => {
      // eslint-disable-next-line quotes
      return !base.occupancyRoomsPercentage.startsWith('-')
        ? `<span class='text-sm font-weight-bolder text-green'>${base.occupancyRoomsPercentage} </span>since yesterday`
        : `<span class='text-sm font-weight-bolder text-danger'>${base.occupancyRoomsPercentage} </span>since yesterday`
    }

    const handleAvailableRoomsDescription = () => {
      // eslint-disable-next-line quotes
      return !base.availableRoomsPercentage.startsWith('-')
        ? `<span class='text-sm font-weight-bolder text-green'>${base.availableRoomsPercentage} </span>since yesterday`
        : `<span class='text-sm font-weight-bolder text-danger'>${base.availableRoomsPercentage} </span>since yesterday`
    }

    const handlePresentCountDescription = () => {
      // eslint-disable-next-line quotes
      return `<span class='text-sm font-weight-bolder text-green'>${base.todayData.staffAttendanceDetails.presentCount} </span> Staff Present Today`
    }

    const handlePercentageData = (todayData, yesterdayData) => {
      let percentageChange
      if (yesterdayData === 0) {
        if (todayData === 0) {
          percentageChange = '0%' // No change if both are zero
        } else {
          percentageChange = '100%' // Custom message
        }
      } else {
        percentageChange = `${(((todayData - yesterdayData) / yesterdayData) * 100).toFixed(2)}%`
      }
      return percentageChange
    }

    const handleSummaryData = () => {
      base.revenuePercentage = handlePercentageData(
        base.todayData.revenueAmount,
        base.yesterdayData.revenueAmount
      )
      base.occupancyRoomsPercentage = handlePercentageData(
        base.todayData.roomDetails.booked,
        base.yesterdayData.roomDetails.booked
      )
      base.availableRoomsPercentage = handlePercentageData(
        base.todayData.roomDetails.available,
        base.yesterdayData.roomDetails.available
      )
      base.todayData.revenueAmount = `₹${base.todayData.revenueAmount}`
      base.todayData.roomDetails.booked = base.todayData.roomDetails.booked.toString()
      base.todayData.roomDetails.available = base.todayData.roomDetails.available.toString()
      base.todayData.staffAttendanceDetails.absentCount =
        base.todayData.staffAttendanceDetails.absentCount.toString()
      base.todayData.staffAttendanceDetails.presentCount =
        base.todayData.staffAttendanceDetails.presentCount.toString()
    }

    const fetchSummaries = () => {
      const options = AuthSvc.getOptions()
      DashboardSvc.fetchSummaries(options)
        .then(response => {
          base.summaryData = response.data?.weeklyResult ?? []
          const monthlyData = response.data?.monthlyResult ?? []
          base.todayData = base.summaryData.find(
            i => new Date(i.date).getDate() === new Date().getDate()
          )
          base.yesterdayData = base.summaryData.find(
            i => new Date(i.date).getDate() === new Date().getDate() - 1
          )
          base.weeklyRevenueAmount = base.summaryData.map(a => a.revenueAmount).reverse()
          base.monthlyRevenueAmount = monthlyData.map(a => a.revenueAmount)
          handleSummaryData()
          base.isLoaded = true
        })
        .catch(error => {
          console.log('Dashboard fetchSummaries error => ', error)
          common.showErrors(error)
        })
    }

    const fetchPlatformAdminSummaries = () => {
      const options = AuthSvc.getOptions()
      DashboardSvc.fetchSummaries(options)
        .then(response => {
          base.platformAdminSummary = response.data
          for (let i = 1; i <= 12; i++) {
            const record = base.platformAdminSummary.monthOnMonthSubscriptionAmount.find(
              m => m._id === i
            )
            base.monthlyRevenueAmount.push(record?.totalAmount || 0)
          }
          const last7Days = calendarUtil.getLast7LongDates()
          last7Days.forEach(date => {
            const isFound = base.platformAdminSummary.last7DaysSubs.find(
              r => new Date(r.date).getTime() === date.getTime()
            )
            base.weeklyRevenueAmount.push(isFound?.totalAmount || 0)
          })
          base.isLoaded = true
        })
        .catch(error => {
          console.log('Dashboard fetchPlatformAdminSummaries error => ', error)
          common.showErrors(error)
        })
    }

    return {
      base,
      handleRevenueDescription,
      handleOccupancyRoomsDescription,
      handleAvailableRoomsDescription,
      handlePresentCountDescription
    }
  }
}
// const sales = {
//   us: {
//     country: "United States",
//     sales: 2500,
//     value: "$230,900",
//     bounce: "29.9%",
//     flag: US,
//   },
//   germany: {
//     country: "Germany",
//     sales: "3.900",
//     value: "$440,000",
//     bounce: "40.22%",
//     flag: DE,
//   },
//   britain: {
//     country: "Great Britain",
//     sales: "1.400",
//     value: "$190,700",
//     bounce: "23.44%",
//     flag: GB,
//   },
//   brasil: {
//     country: "Brasil",
//     sales: "562",
//     value: "$143,960",
//     bounce: "32.14%",
//     flag: BR,
//   },
// };
</script>

<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div v-if="base.isLoaded && !base.isPlatformAdministrator" class="col-lg-12">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="Today's Revenue"
              :value="base.todayData.revenueAmount"
              :description="handleRevenueDescription()"
              :icon="{
                component: 'ni ni-money-coins',
                background: 'bg-gradient-primary',
                shape: 'rounded-circle'
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="Today's Occupancy"
              :value="base.todayData.roomDetails.booked"
              :description="handleOccupancyRoomsDescription()"
              :icon="{
                component: 'ni ni-world',
                background: 'bg-gradient-danger',
                shape: 'rounded-circle'
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="Available Rooms"
              :value="base.todayData.roomDetails.available"
              :description="handleAvailableRoomsDescription()"
              :icon="{
                component: 'ni ni-paper-diploma',
                background: 'bg-gradient-success',
                shape: 'rounded-circle'
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <!-- ni ni-cart -->
            <mini-statistics-card
              title="Staff Absent Today"
              :value="base.todayData.staffAttendanceDetails.absentCount"
              :description="handlePresentCountDescription()"
              :icon="{
                component: 'ni ni-single-02',
                background: 'bg-gradient-warning',
                shape: 'rounded-circle'
              }"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 mb-lg">
            <!-- line chart -->
            <div class="card z-index-2">
              <!-- <i class='fa fa-arrow-up text-green'></i>
      <span class='font-weight-bold'>4% more</span>  -->
              <gradient-line-chart
                id="chart-line"
                title="Revenue Overview"
                description="Last Week Day wise Revenue"
                :chart="{
                  labels: base.dates,
                  datasets: [
                    {
                      label: 'Revenue',
                      data: base.weeklyRevenueAmount
                    }
                  ]
                }"
              />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card z-index-2">
              <gradient-line-chart
                id="chart-line1"
                title="Revenue Monthly Overview"
                description="Month on month revenue change"
                :chart="{
                  labels: [
                    'Jan',
                    'Feb',
                    'Mar',
                    'Apr',
                    'May',
                    'Jun',
                    'Jul',
                    'Aug',
                    'Sep',
                    'Oct',
                    'Nov',
                    'Dec'
                  ],
                  datasets: [
                    {
                      label: 'Revenue',
                      data: base.monthlyRevenueAmount
                    }
                  ]
                }"
              />
            </div>
            <!-- <carousel /> -->
          </div>
        </div>
      </div>
      <div v-if="base.isLoaded && base.isPlatformAdministrator" class="col-lg-12">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="Total Subscriptions this Month"
              :value="base.platformAdminSummary.totalSubscriptionsThisMonth.toString()"
              :icon="{
                component: 'ni ni-money-coins',
                background: 'bg-gradient-primary',
                shape: 'rounded-circle'
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="Total Revenue this Month"
              :value="base.platformAdminSummary.totalSubscriptionAmount?.toString() || '0'"
              :icon="{
                component: 'ni ni-world',
                background: 'bg-gradient-danger',
                shape: 'rounded-circle'
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="Total Bookings on ZATA"
              :value="base.platformAdminSummary.totalBookings.toString()"
              :icon="{
                component: 'ni ni-paper-diploma',
                background: 'bg-gradient-success',
                shape: 'rounded-circle'
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="Total Users on ZATA"
              :value="base.platformAdminSummary.totalUsers.toString()"
              :icon="{
                component: 'ni ni-single-02',
                background: 'bg-gradient-warning',
                shape: 'rounded-circle'
              }"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 mb-lg">
            <div class="card z-index-2">
              <gradient-line-chart
                id="chart-line"
                title="Revenue Overview"
                description="Last Week Day wise Revenue"
                :chart="{
                  labels: base.dates,
                  datasets: [
                    {
                      label: 'Revenue',
                      data: base.weeklyRevenueAmount
                    }
                  ]
                }"
              />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card z-index-2">
              <gradient-line-chart
                id="chart-line1"
                title="Revenue Monthly Overview"
                description="Month on month revenue change"
                :chart="{
                  labels: [
                    'Jan',
                    'Feb',
                    'Mar',
                    'Apr',
                    'May',
                    'Jun',
                    'Jul',
                    'Aug',
                    'Sep',
                    'Oct',
                    'Nov',
                    'Dec'
                  ],
                  datasets: [
                    {
                      label: 'Revenue',
                      data: base.monthlyRevenueAmount
                    }
                  ]
                }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
