import { jwtDecode } from 'jwt-decode'
import { EventBus } from '@/eventBus'
const localStorage = require('../services/localStorage')
const _ = require('lodash')

const getErrorStatusCode = function (err) {
  return _.get(err, 'response.status', '')
}

const onError = error => {
  const token = localStorage.getValue('token')
  if (token) {
    const decoded = jwtDecode(token)
    const errorStatusCode = getErrorStatusCode(error)
    const jwtExpiry = decoded.exp
    const currentTime = Date.now().valueOf() / 1000
    if (errorStatusCode === 401 || jwtExpiry < currentTime) {
      console.log('logout')
      EventBus.$emit('session-timeout')
    }
    return Promise.reject(error)
  }
}

const onResponse = function (response) {
  return response
}

export default {
  onResponse,
  onError
}
