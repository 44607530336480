const constants = require('../constants')
const moment = require('moment')
const momentTz = require('moment-timezone')

const isWeekend = date => {
  const day = date.day()
  return day === 0 || day === 6 // 0 is Sunday, 6 is Saturday
}

// Function to generate dates array for 30 days by default from today
const generateDatesArray = (days = 30, fromDate = null) => {
  const dateArray = []
  const today = fromDate ? moment(fromDate) : moment().startOf('day')

  for (let i = 0; i < days; i++) {
    const futureDate = today.clone().add(i, 'days')
    const dayNumber = futureDate.date()
    const dayOfWeek = futureDate.format('ddd') // First letter of day of the week
    const formattedDate = `${dayNumber} ${dayOfWeek}`
    const monthNumber = futureDate.month() + 1
    const datePayload = {
      formattedDate,
      date: futureDate.toISOString(),
      isWeekend: isWeekend(futureDate),
      month: constants.MONTHS[monthNumber]
    }
    dateArray.push(datePayload)
  }

  return dateArray
}

const getLast7Dates = () => {
  const dates = []
  const today = moment().startOf('day')
  for (let i = 0; i < 7; i++) {
    dates.unshift(today.clone().subtract(i, 'days').format('D-MMM-YYYY'))
  }
  return dates
}

const getLast7LongDates = () => {
  const dates = []
  const today = moment().startOf('day')
  for (let i = 0; i < 7; i++) {
    dates.unshift(today.clone().subtract(i, 'days')._d)
  }
  return dates
}

const getShortDateFormat = value => {
  const date = moment(value)
  return date.format('DD-MMMM-YYYY')
}

// Function to generate dates of the current month
const currentMonthDates = () => {
  const dateArray = []
  const today = moment().startOf('day')

  // Get the start and end of the current month
  const startOfMonth = today.clone().startOf('month')
  const endOfMonth = today.clone().endOf('month')
  const totalDays = endOfMonth.date() // Total number of days in the month

  for (let i = 0; i < totalDays; i++) {
    const futureDate = startOfMonth.clone().add(i, 'days')
    const dayNumber = futureDate.date()
    const dayOfWeek = futureDate.format('ddd') // First letter of day of the week
    const formattedDate = `${dayNumber} ${dayOfWeek}`
    const monthNumber = futureDate.month() + 1
    const datePayload = {
      formattedDate,
      date: futureDate.toISOString(),
      isWeekend: isWeekend(futureDate),
      month: constants.MONTHS[monthNumber]
    }
    dateArray.push(datePayload)
  }

  return dateArray
}

const getCurrentMonth = () => {
  const currentMonth = moment().month() + 1
  return currentMonth
}

const getCurrentYear = () => {
  const currentYear = moment().year()
  return currentYear
}

const getTodaysDate = () => {
  return moment().startOf('day')
}

const getDateQuery = dateStr => {
  // Parse the date in the specified timezone at the start of the day (00:00:00)
  const startOfDay = momentTz.tz(dateStr, 'YYYY-MM-DD', 'Asia/Kolkata').startOf('day')

  // Calculate the end of the day (24:00:00, which is actually the next day's 00:00:00)
  const endOfDay = startOfDay.clone().add(1, 'day')

  // Convert both start and end to UTC
  const utcStartOfDay = startOfDay.utc().toDate() // Converts to native JS Date in UTC
  const utcEndOfDay = endOfDay.utc().toDate() // Converts to native JS Date in UTC

  // Prepare the MongoDB query with $gte and $lt
  const query = {
    $gte: utcStartOfDay, // Greater than or equal to 00:00:00Z
    $lt: utcEndOfDay // Less than 24:00:00Z (which is next day's 00:00:00)
  }

  return query
}

module.exports = {
  generateDatesArray,
  getLast7Dates,
  getShortDateFormat,
  currentMonthDates,
  getCurrentMonth,
  getCurrentYear,
  getDateQuery,
  getTodaysDate,
  getLast7LongDates
}
