<template>
  <div class="card">
    <div class="card-header pr-0 pb-0">
      <el-row>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-date-picker
                v-model="base.fromDate"
                type="date"
                placeholder="Pick a day"
                size="large"
                class="w-100"
                format="DD-MM-YYYY"
                @change="handleNoOfDaysChange"
              />
            </el-col>
            <el-col :span="12">
              <el-select
                v-model="base.noOfDays"
                size="large"
                placeholder="No. Of Days"
                @change="handleNoOfDaysChange"
              >
                <el-option label="15" :value="15" />
                <el-option label="30" :value="30" />
                <el-option label="45" :value="45" />
                <el-option label="60" :value="60" />
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-button
            class="text-uppercase ms-auto fr"
            type="primary"
            :icon="Plus"
            @click="handleAddAttendance"
          >
            Attendance
          </el-button>
        </el-col>
      </el-row>
    </div>
    <div class="card-body px-0 pt-0 pb-2 mt-2 calendar-main">
      <el-table
        :data="base.dayWiseAttendance"
        row-key="staffId"
        border
        default-expand-all
        max-height="calc(100vh - 200px)"
        :stripe="true"
        :scrollbar-always-on="true"
        :cell-class-name="getTableRowClass"
        @cell-click="handleCellClick"
      >
        <el-table-column fixed prop="name" label="Staff Name" width="150" />
        <el-table-column
          v-for="monthHeading in base.monthHeadings"
          :key="monthHeading.month"
          :label="monthHeading.month"
        >
          <el-table-column
            v-for="dt in monthHeading.dates"
            :key="dt.date"
            width="75"
            :label="dt.formattedDate"
          >
            <template #default="scope">
              <el-tooltip
                class="box-item"
                effect="dark"
                :content="getCellValue(scope.row, dt)"
                placement="top"
              >
                <div
                  style="
                    align-items: center;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                  "
                >
                  {{ getCellValue(scope.row, dt) }}
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      v-model="base.attendanceFormVisible"
      title="Staff Attendance"
      width="90%"
      destroy-on-close
      align-center
    >
      <el-form :model="base.attendanceForm" label-position="top">
        <!-- <el-row class="mt-2" :gutter="20">
          <el-col :span="24">
            <p class="text-uppercase text-sm">Room Information</p>
          </el-col>
        </el-row> -->
        <el-row :gutter="20" class="mt10">
          <el-col :span="8">
            <el-form-item label="Staff Name">
              <el-select
                v-model="base.attendanceForm.staffId"
                placeholder="Select"
                size="large"
                @change="handleStaffIdChange"
              >
                <el-option
                  v-for="item in base.staffDetails"
                  :key="item.staffId"
                  :label="item.fullName"
                  :value="item.staffId"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Attendance Date">
              <el-date-picker
                v-model="base.attendanceForm.date"
                type="date"
                placeholder="Pick a day"
                size="large"
                class="w-100"
                format="DD-MM-YYYY"
                :disabled-date="disabledDateBeforeToday"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Is Absent?">
              <el-switch
                v-model="base.attendanceForm.isAbsent"
                active-text="Yes"
                inactive-text="No"
                @change="handleIsAbsentChange"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Check In Time">
              <el-time-picker
                v-model="base.attendanceForm.checkInTime"
                value-format="HH:mm:ss"
                :disabled="base.attendanceForm.isAbsent"
                size="large"
                class="w-100"
                arrow-control
                placeholder="Arbitrary time"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Check Out Time">
              <el-time-picker
                v-model="base.attendanceForm.checkOutTime"
                value-format="HH:mm:ss"
                :disabled="base.attendanceForm.isAbsent"
                size="large"
                class="w-100"
                arrow-control
                placeholder="Arbitrary time"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="base.attendanceFormVisible = false">Cancel</el-button>
          <el-button type="primary" @click="handleSave">Save</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, onMounted } from 'vue'
import { Search, Plus } from '@element-plus/icons-vue'
import { ElNotification } from 'element-plus'
import _ from 'lodash'
import StaffDetailSvc from '@/services/staffDetail'
import StaffAttendanceSvc from '@/services/staffAttendance'
import AuthSvc from '@/services/auth'
import calendarUtil from '@/utils/calendarUtil'
import moment from 'moment'
import common from '@/utils/common'

export default {
  name: 'StaffAttendanceTable',
  setup() {
    const base = reactive({
      attendanceFormVisible: false,
      attendanceForm: {},
      staffDetails: [],
      dates: [],
      monthHeadings: [],
      dayWiseAttendance: [],
      fromDate: moment().startOf('day'),
      noOfDays: 30
    })

    onMounted(async () => {
      base.dates = calendarUtil.currentMonthDates()
      console.log('base.dates', base.dates)
      const monthHeadings = _.uniqBy(base.dates, 'month')
      monthHeadings.forEach(mt => {
        const mDates = base.dates.filter(dt => dt.month === mt.month)
        base.monthHeadings.push({ month: mt.month, dates: mDates })
      })
      console.log('monthHeadings', base.monthHeadings)
      await fetchStaffAttendance()
      fetchStaffDetails()
    })

    const fetchStaffDetails = () => {
      const options = AuthSvc.getOptions()
      StaffDetailSvc.find(options, {}, undefined, 1, 10000)
        .then(response => {
          base.staffDetails = response.data.records.map(r => {
            r.fullName = `${r.firstName} ${r.lastName}`
            return r
          })
        })
        .catch(error => {
          console.log('fetchStaffDetails fetch error => ', error)
          common.showErrors(error)
        })
    }

    const handleNoOfDaysChange = async () => {
      console.log('base.fromDate', base.fromDate)
      base.dates = calendarUtil.generateDatesArray(base.noOfDays, base.fromDate)
      const monthHeadings = _.uniqBy(base.dates, 'month')
      const headings = []
      monthHeadings.forEach(mt => {
        const mDates = base.dates.filter(dt => dt.month === mt.month)
        headings.push({ month: mt.month, dates: mDates })
      })
      base.monthHeadings = headings
      console.log('monthHeadings', base.monthHeadings)
      await fetchStaffAttendance()
    }

    const disabledDateBeforeToday = time => {
      return time.getTime() < new Date().setHours(0, 0, 0, 0)
    }

    const fetchStaffAttendance = async () => {
      const options = AuthSvc.getOptions()
      const month = calendarUtil.getCurrentMonth()
      await StaffAttendanceSvc.find(options, { month }, undefined, 1, 1000)
        .then(response => {
          base.dayWiseAttendance = response.data.records
          console.log('base.dayWiseAttendance', response.data.records)
        })
        .catch(error => {
          console.log('StaffAttendanceTable fetch error => ', error)
          common.showErrors(error)
        })
    }

    const handleCreateNewAttendanceForm = () => {
      return {
        name: '',
        staffId: '',
        month: calendarUtil.getCurrentMonth(),
        year: calendarUtil.getCurrentYear(),
        checkInTime: '',
        checkOutTime: '',
        date: calendarUtil.getTodaysDate()._d,
        isAbsent: false,
        attendance: []
      }
    }

    const handleAddAttendance = (roomNumber, checkIn) => {
      base.attendanceForm = handleCreateNewAttendanceForm(roomNumber, checkIn)
      base.attendanceFormVisible = true
    }

    const handleSave = () => {
      const options = AuthSvc.getOptions()
      let attendanceForm = _.clone(base.attendanceForm)
      const attendance = {
        checkInTime: attendanceForm.checkInTime,
        checkOutTime: attendanceForm.checkOutTime,
        date: attendanceForm.date,
        isAbsent: attendanceForm.isAbsent
      }
      attendanceForm = _.omit(attendanceForm, ['checkInTime', 'checkOutTime', 'date', 'isAbsent'])
      const index = attendanceForm.attendance.findIndex(
        a => new Date(a.date).getTime() === new Date(attendance.date).getTime()
      )
      if (index !== -1) {
        attendanceForm.attendance[index] = attendance
      } else {
        attendanceForm.attendance.push(attendance)
      }
      StaffAttendanceSvc.save(options, attendanceForm)
        .then(response => {
          base.attendanceFormVisible = false
          const message =
            response.status === 201
              ? 'Attendance created successfully'
              : 'Attendance updated successfully'
          fetchStaffAttendance()
          ElNotification({
            message,
            type: 'success'
          })
        })
        .catch(error => {
          console.log('AttendanceTable handleSave error => ', error)
          common.showErrors(error)
        })
    }

    const handleCellClick = (row, col) => {
      console.log('args', row, col, row[col.rawColumnKey])
      const colDate = base.dates.find(dt => dt.formattedDate === col.label)
      const attendance = row.attendance.find(r => r.date === colDate.date)
      console.log('attendance', attendance)
      if (row) {
        const options = AuthSvc.getOptions()
        StaffAttendanceSvc.findById(options, row._id)
          .then(res => {
            console.log('res', res.data)
            const attendanceForm = res.data
            attendanceForm.checkInTime = ''
            attendanceForm.checkOutTime = ''
            attendanceForm.date = colDate.date
            attendanceForm.isAbsent = false
            base.attendanceForm = attendanceForm
            base.attendanceFormVisible = true
          })
          .catch(error => {
            console.log('StaffAttendanceTable handleCellClick error => ', error)
            common.showErrors(error)
          })
      } else {
        handleAddAttendance(row.roomNumber, colDate.date)
      }
    }

    const getTableRowClass = args => {
      if (args.column.level === 2) {
        const colDate = base.dates.find(dt => dt.formattedDate === args.column.label)
        const attendance = args.row.attendance.find(r => r.date === colDate.date)
        let rowClass = attendance
          ? attendance.isAbsent
            ? 'absent'
            : 'room-date-reserved'
          : 'calendar-cell'
        return rowClass
      }
      return ''
    }

    const getCellValue = (row, dt) => {
      const attendance = row.attendance.find(r => r.date === dt.date)
      return attendance ? (attendance.isAbsent ? 'A' : 'P') : 'N/A'
    }

    const handleStaffIdChange = value => {
      base.attendanceForm.name = base.staffDetails.find(staff => staff.staffId === value).fullName
    }

    const handleIsAbsentChange = () => {
      if (base.attendanceForm.isAbsent) {
        base.attendanceForm.checkInTime = ''
        base.attendanceForm.checkOutTime = ''
      }
    }

    return {
      base,
      Search,
      Plus,
      handleAddAttendance,
      disabledDateBeforeToday,
      handleSave,
      getTableRowClass,
      handleCellClick,
      getCellValue,
      handleNoOfDaysChange,
      handleStaffIdChange,
      handleIsAbsentChange
    }
  }
}
</script>

<style>
.table-max-height {
  min-height: calc(100vh - 200px);
  max-height: calc(100vh - 200px);
}
.calendar-main {
  margin: 1.5rem;
}
.calendar-row {
  padding: 10px;
}
.calendar-border {
  border: solid;
  border-width: thin;
}
.room-date-reserved {
  /* background-image: linear-gradient(310deg, #d60808, #ff6690); */
  background-color: rgb(64, 201, 112) !important;
  color: white;
  font-weight: 500;
}
.absent {
  /* background-image: linear-gradient(310deg, #d60808, #ff6690); */
  background-color: rgb(247, 96, 93) !important;
  color: white;
  font-weight: 500;
}
.calendar-cell {
  cursor: pointer;
}
.calender-weekend-header {
  background-color: rgb(245, 233, 233) !important;
  color: rgb(51, 51, 51) !important;
}
.calendar-header {
  border-right: 1px solid rgb(192, 192, 192);
  background-color: #f3f3f3 !important;
  color: rgb(51, 51, 51) !important;
}
.el-dialog__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}
</style>
