import { ElNotification } from 'element-plus'
import _ from 'lodash'

const showErrors = error => {
  if (_.has(error, 'response.data.message')) {
    if (_.has(error, 'response.data.fields') && Array.isArray(error.response.data.fields)) {
      ElNotification({
        title: 'Error',
        message: error?.response?.data?.fields[0]?.message ?? error.response.data.message,
        type: 'error'
      })
    } else {
      ElNotification({
        title: 'Error',
        message: error.response.data.message,
        type: 'error'
      })
    }
  } else {
    ElNotification({
      title: 'Error',
      message: error.response ? error.response.data : error.response,
      type: 'error'
    })
  }
}

const showMandatoryValidation = () => {
  ElNotification({
    title: 'Error',
    message: 'Please fill all mandatory fields',
    type: 'error'
  })
}

export default { showErrors, showMandatoryValidation }
