import _ from 'lodash'
import constants from '@/constants'

const baseAxiosClient = require('./baseAxiosClient')
const URL = constants.BASE_URL + 'fnbInvoices'

class FNBInvoice {
  static save(options, fnbInvoice) {
    if (fnbInvoice._id) {
      return this.update(options, fnbInvoice)
    } else {
      return this.create(options, fnbInvoice)
    }
  }

  static create(options, payload) {
    const client = baseAxiosClient.getClient(options)
    return client.post(URL, payload)
  }

  static generate(options, id) {
    const client = baseAxiosClient.getClient(options)
    return client.post(`${URL}/${id}/generate`, {}, { responseType: 'blob' })
  }

  static update(options, payload) {
    const client = baseAxiosClient.getClient(options)
    return client.put(
      `${URL}/${payload._id}`,
      _.omit(payload, ['_id', 'createdAt', 'updatedAt', 'createdBy', 'updatedBy', 'invoiceNumber'])
    )
  }

  static find(options, query, fields, page, limit, sort) {
    const client = baseAxiosClient.getClient(options)
    const params = {
      q: JSON.stringify(query),
      limit,
      page,
      fields,
      sort
    }
    return client.get(URL, { params })
  }

  static findById(options, id, fields) {
    const client = baseAxiosClient.getClient(options)
    return client.get(`${URL}/${id}`, { params: { fields } })
  }

  static remove(options, id) {
    const client = baseAxiosClient.getClient(options)
    return client.delete(`${URL}/${id}`)
  }
}

export default FNBInvoice
