import _ from 'lodash'
import constants from '@/constants'

const baseAxiosClient = require('./baseAxiosClient')
const URL = constants.BASE_URL + 'staffDetails'

class StaffDetail {
  static save(options, staffDetail) {
    if (staffDetail._id) {
      return this.update(options, staffDetail)
    } else {
      return this.create(options, staffDetail)
    }
  }

  static create(options, payload) {
    const client = baseAxiosClient.getClient(options)
    return client.post(URL, payload)
  }

  static update(options, payload) {
    const client = baseAxiosClient.getClient(options)
    return client.put(
      `${URL}/${payload._id}`,
      _.omit(payload, ['_id', 'createdAt', 'updatedAt', 'createdBy', 'updatedBy'])
    )
  }

  static find(options, query, fields, page, limit, sort) {
    const client = baseAxiosClient.getClient(options)
    const params = {
      q: JSON.stringify(query),
      limit,
      page,
      fields,
      sort
    }
    return client.get(URL, { params })
  }

  static findById(options, id, fields) {
    const client = baseAxiosClient.getClient(options)
    return client.get(`${URL}/${id}`, { params: { fields } })
  }

  static remove(options, id) {
    const client = baseAxiosClient.getClient(options)
    return client.delete(`${URL}/${id}`)
  }
}

export default StaffDetail
