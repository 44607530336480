import constants from '@/constants'

const baseAxiosClient = require('./baseAxiosClient')
const URL = constants.BASE_URL + 'dashboard'

class Dashboard {
  static fetchSummaries(options) {
    const client = baseAxiosClient.getClient(options)
    return client.get(`${URL}/fetchSummaries`)
  }
}

export default Dashboard
