<template>
  <div class="overflow-hidden card move-on-hover">
    <div class="card-body">
      <div class="d-flex">
        <h6 class="mb-0 me-3">08:00</h6>
        <h6 class="mb-0">
          Synk up with Mark
          <small class="text-secondary font-weight-normal">Hangouts</small>
        </h6>
      </div>
      <hr class="horizontal dark" />
      <div class="d-flex">
        <h6 class="mb-0 me-3">09:30</h6>
        <h6 class="mb-0">
          Gym
          <br />
          <small class="text-secondary font-weight-normal">World Class</small>
        </h6>
      </div>
      <hr class="horizontal dark" />
      <div class="d-flex">
        <h6 class="mb-0 me-3">11:00</h6>
        <h6 class="mb-0">
          Design Review
          <br />
          <small class="text-secondary font-weight-normal">Zoom</small>
        </h6>
      </div>
    </div>
    <a
      href="javascript:;"
      class="py-1 text-center bg-gray-100 w-100"
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      title="Show More"
    >
      <i class="fas fa-chevron-down text-primary"></i>
    </a>
  </div>
</template>
