<script setup>
import { onBeforeUnmount, onBeforeMount, reactive } from 'vue'
import Carousel from './components/Carousel.vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
// import { Lock } from '@element-plus/icons-vue'
// import Navbar from "@/examples/PageLayout/Navbar.vue";
// import ArgonInput from "@/components/ArgonInput.vue";
// import ArgonSwitch from '@/components/ArgonSwitch.vue'
// import ArgonButton from "@/components/ArgonButton.vue";
import AuthSvc from '@/services/auth'
import common from '@/utils/common'
const body = document.getElementsByTagName('body')[0]
const router = useRouter()

const store = useStore()
const base = reactive({
  email: '',
  password: ''
})
onBeforeMount(() => {
  store.state.hideConfigButton = true
  store.state.showNavbar = false
  store.state.showSidenav = false
  store.state.showFooter = false
  body.classList.remove('bg-gray-100')
})
onBeforeUnmount(() => {
  store.state.hideConfigButton = false
  store.state.showNavbar = true
  store.state.showSidenav = true
  store.state.showFooter = true
  body.classList.add('bg-gray-100')
})
const handleLogin = () => {
  const payload = {
    login: base.email,
    password: base.password
  }
  AuthSvc.login(payload)
    .then(() => {
      router.push('/dashboard')
    })
    .catch(error => {
      console.log('Sign in handleLogin error => ', error)
      common.showErrors(error)
    })
}

const handleSignUpClick = () => {
  router.push('/signup')
}
</script>

<template>
  <!-- <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          v-bind:darkMode="true"
          isBtn="bg-gradient-success"
        />
      </div>
    </div>
  </div> -->
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
              <div class="card card-plain">
                <div class="pb-0 card-header login-header text-start">
                  <h4 class="font-weight-bolder">Sign In</h4>
                  <p class="mb-0">Enter your email and password to sign in</p>
                </div>
                <div class="card-body">
                  <el-form label-position="top">
                    <div class="mb-3">
                      <el-input
                        v-model="base.email"
                        class="login-input"
                        size="large"
                        placeholder="Login"
                        autocomplete="off"
                      >
                        <template #prefix>
                          <i class="fa-solid fa-user"></i>
                        </template>
                      </el-input>
                    </div>
                    <div class="mb-3">
                      <el-input
                        v-model="base.password"
                        class="login-input"
                        size="large"
                        autocomplete="off"
                        type="password"
                        placeholder="Password"
                        show-password
                      >
                        <template #prefix>
                          <i class="fa-solid fa-lock"></i>
                        </template>
                      </el-input>
                    </div>
                    <!-- <argon-switch id="rememberMe" name="remember-me"
                      >Remember me</argon-switch
                    > -->

                    <div class="text-center">
                      <!-- <argon-button
                        class="mt-4"
                        variant="gradient"
                        color="success"
                        fullWidth
                        size="lg"
                        @click="handleLogin"
                        >Sign in</argon-button
                      > -->
                      <el-button
                        class="mt-4 w-100 btn-lg text-sm"
                        type="primary"
                        @click="handleLogin"
                      >
                        Sign in
                      </el-button>
                    </div>
                  </el-form>
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    Don't have an account?
                    <a
                      href="javascript:;"
                      class="text-success text-gradient font-weight-bold"
                      @click="handleSignUpClick"
                    >
                      Sign up
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div
              class="top-0 my-auto text-center col-7 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
            >
              <carousel />
              <!-- <div
                class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                style="
                  background-image: url(&quot;https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-ill.jpg&quot;);
                  background-size: cover;
                "
              >
                <span class="mask bg-gradient-success opacity-6"></span>
                <h4
                  class="mt-5 text-white font-weight-bolder position-relative"
                >
                  "Attention is the new currency"
                </h4>
                <p class="text-white position-relative">
                  The more effortless the writing looks, the more effort the
                  writer actually put into the process.
                </p>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<style>
.login-header {
  background-color: transparent !important;
}
</style>
