<template>
  <el-container class="landing-page">
    <el-header class="landing-nav">
      <el-row class="nav-container">
        <a href="#" class="logo">
          <svg viewBox="0 0 100 100" width="40" height="40">
            <circle cx="50" cy="50" r="45" fill="none" stroke="#00A3FF" stroke-width="10" />
            <path d="M35 30 L65 30 L35 70 L65 70" stroke="#000000" stroke-width="8" fill="none" />
            <path d="M30 40 L45 55" stroke="#00A3FF" stroke-width="8" fill="none" />
          </svg>
          ZataStays
        </a>
        <el-row class="nav-links">
          <a href="#" @click="base.currentTab = 'home'">Home</a>
          <a href="#">Pricing</a>
          <a href="#">Contact</a>
          <a href="#" @click="base.currentTab = 'login'">Login</a>
          <a href="#" class="landing-btn landing-btn-outline">Schedule Demo</a>
          <a href="#" class="landing-btn landing-btn-primary" @click="base.currentTab = 'signup'">
            Start Free Trial
          </a>
        </el-row>
      </el-row>
    </el-header>
    <el-row v-if="base.currentTab === 'home'">
      <el-row class="hero">
        <el-col :span="14">
          <div class="hero-content">
            <h1>Easy to use, All-In-One Hotel Management System</h1>
            <div class="hero-buttons">
              <a href="#" class="btn btn-primary">Start free trial</a>
              <a href="#" class="btn btn-outline">Schedule a Demo</a>
            </div>
          </div>
        </el-col>
        <el-col :span="10">
          <div class="hero-image">
            <img src="../assets/landingPage/reservation.png" alt="Mobile App Interface" />
          </div>
        </el-col>
      </el-row>
      <el-row class="features">
        <!-- <el-col> -->
        <div class="features-container">
          <div class="features-header">
            <h2>
              Automate Your Property's Operation With
              <br />
              <span>ZataStays HMS</span>
            </h2>
            <p>
              With ZataStays PMS, managing daily operations, reservations, housekeeping,
              maintenance, billing, and reporting is a breeze. The interface is simple and
              straightforward, ensuring that all tasks can be completed in one place
            </p>
          </div>

          <div class="features-grid">
            <div class="dashboard-preview">
              <img src="../assets/landingPage/dashboard.png" alt="Dashboard Interface" />
            </div>
            <el-row class="features-list">
              <el-col :span="12" class="feature-list-item">
                <el-row>
                  <el-col :span="4" class="feature-icon">
                    <el-row class="feature-icon-box">
                      <el-icon :size="35"><TrendCharts /></el-icon>
                    </el-row>
                  </el-col>
                  <el-col :span="20" class="feature-list-content">
                    <h5>Insights Dashboard</h5>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="12" class="feature-list-item">
                <el-row>
                  <el-col :span="4" class="feature-icon">
                    <el-row class="feature-icon-box">
                      <el-icon :size="35"><Calendar /></el-icon>
                    </el-row>
                  </el-col>
                  <el-col :span="20" class="feature-list-content">
                    <h5>Reservation Manager</h5>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="12" class="feature-list-item">
                <el-row>
                  <el-col :span="4" class="feature-icon">
                    <el-row class="feature-icon-box">
                      <el-icon :size="35"><CreditCard /></el-icon>
                    </el-row>
                  </el-col>
                  <el-col :span="20" class="feature-list-content">
                    <h5>Quick Bill Generator</h5>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="12" class="feature-list-item">
                <el-row>
                  <el-col :span="4" class="feature-icon">
                    <el-row class="feature-icon-box">
                      <el-icon :size="35"><UserFilled /></el-icon>
                    </el-row>
                  </el-col>
                  <el-col :span="20" class="feature-list-content">
                    <h5>Employee Hub</h5>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="12" class="feature-list-item">
                <el-row>
                  <el-col :span="4" class="feature-icon">
                    <el-row class="feature-icon-box">
                      <el-icon :size="35"><KnifeFork /></el-icon>
                    </el-row>
                  </el-col>
                  <el-col :span="20" class="feature-list-content">
                    <h5>Dining Manager</h5>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>

          <div style="text-align: center; margin-top: 3rem">
            <a href="#" class="btn btn-primary">Book a Free Demo</a>
          </div>
        </div>
        <!-- </el-col> -->
      </el-row>
      <el-row class="testimonials">
        <el-col>
          <h3>Hear from our clients</h3>
          <el-row class="testimonial-grid" :gutter="20">
            <el-col :span="12">
              <el-row class="testimonial-card">
                <p>
                  "This HMS has revolutionized how we manage our properties. The automation features
                  save us countless hours every month."
                </p>
                <div class="author">Ram Gopal</div>
              </el-row>
            </el-col>
            <el-col :span="12">
              <el-row class="testimonial-card">
                <p>
                  "The staff tracking system has made it so much easier to handle staff attendance
                  and their salaries."
                </p>
                <div class="author">Krishna Kumar</div>
              </el-row>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-row>
    <el-row v-else-if="base.currentTab === 'login'">
      <el-col class="login-content">
        <div class="login-container">
          <div class="login-header">
            <h1 class="login-title">Welcome back</h1>
            <p class="login-subtitle">Sign in to your ZataStays account</p>
          </div>
          <el-form label-position="top">
            <div class="form-group">
              <label for="email" class="form-label">Email address</label>
              <el-input
                v-model="base.email"
                class="login-input"
                size="large"
                placeholder="Login"
                autocomplete="off"
              >
                <template #prefix>
                  <i class="fa-solid fa-user"></i>
                </template>
              </el-input>
            </div>
            <div class="form-group">
              <label for="password" class="form-label">Password</label>
              <el-input
                v-model="base.password"
                class="login-input"
                size="large"
                autocomplete="off"
                type="password"
                placeholder="Password"
                show-password
              >
                <template #prefix>
                  <i class="fa-solid fa-lock"></i>
                </template>
              </el-input>
            </div>
            <el-button
              class="w-100 btn-lg text-sm login-button"
              type="primary"
              @click="handleLogin"
            >
              Sign in
            </el-button>
            <div class="form-footer">
              Don't have an account?
              <a href="/trial" class="link primary-color">Start free trial</a>
            </div>
          </el-form>
        </div>
      </el-col>
    </el-row>
    <el-row v-else-if="base.currentTab === 'signup'">
      <el-col class="signup-content">
        <div class="signup-container">
          <div class="signup-header">
            <h1 class="signup-title">Create Your Account</h1>
            <p class="signup-subtitle">Start managing your property with ZataStays</p>
          </div>
          <el-row v-if="base.activeFormIndex === 0">
            <el-col :span="24">
              <p class="text-uppercase text-sm">Hotel Information</p>
            </el-col>
          </el-row>
          <el-form v-if="base.activeFormIndex === 0" :model="base.hotelForm" label-position="top">
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="Hotel Name">
                  <el-input v-model="base.hotelForm.name" size="large" autocomplete="off" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Mobile Number">
                  <el-input
                    v-model="base.hotelForm.mobile"
                    maxlength="10"
                    size="large"
                    autocomplete="off"
                    @input="
                      () => {
                        base.hotelForm.mobile = base.hotelForm.mobile.replace(/[^0-9]/g, '')
                      }
                    "
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Email">
                  <el-input v-model="base.hotelForm.emailId" size="large" autocomplete="off" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Address">
                  <el-input v-model="base.hotelForm.address" size="large" autocomplete="off" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Website">
                  <el-input v-model="base.hotelForm.website" size="large" autocomplete="off" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Check In Time">
                  <el-time-picker
                    v-model="base.hotelForm.checkInTime"
                    arrow-control
                    size="large"
                    class="w-100"
                    placeholder="Pick a time"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Check Out Time">
                  <el-time-picker
                    v-model="base.hotelForm.checkOutTime"
                    arrow-control
                    size="large"
                    class="w-100"
                    placeholder="Pick a time"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Plans">
                  <el-select v-model="base.hotelForm.plan" placeholder="Select" size="large">
                    <el-option v-for="item in base.plans" :key="item" :label="item" :value="item" />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <el-row v-if="base.activeFormIndex === 1">
            <el-col :span="24">
              <p class="text-uppercase text-sm">User Information</p>
            </el-col>
          </el-row>
          <el-form v-if="base.activeFormIndex === 1" :model="base.userForm" label-position="top">
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="Title">
                  <el-select v-model="base.userForm.title" placeholder="Select" size="large">
                    <el-option
                      v-for="item in base.titleOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="First Name">
                  <el-input v-model="base.userForm.firstName" size="large" autocomplete="off" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Middle Name">
                  <el-input v-model="base.userForm.middleName" size="large" autocomplete="off" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Last Name">
                  <el-input v-model="base.userForm.lastName" size="large" autocomplete="off" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Email">
                  <el-input v-model="base.userForm.email" size="large" autocomplete="off" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Password">
                  <el-input
                    v-model="base.userForm.password"
                    class="login-input"
                    size="large"
                    autocomplete="off"
                    type="password"
                    placeholder="Password"
                    show-password
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Gender">
                  <el-select v-model="base.userForm.gender" placeholder="Select" size="large">
                    <el-option
                      v-for="item in base.genderOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Mobile Number">
                  <el-input
                    v-model="base.userForm.mobileNumber"
                    maxlength="10"
                    size="large"
                    autocomplete="off"
                    @input="
                      () => {
                        base.userForm.mobileNumber = base.userForm.mobileNumber.replace(
                          /[^0-9]/g,
                          ''
                        )
                      }
                    "
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Alternate Number">
                  <el-input
                    v-model="base.userForm.alternateNumber"
                    maxlength="10"
                    size="large"
                    autocomplete="off"
                    @input="
                      () => {
                        base.userForm.alternateNumber = base.userForm.alternateNumber.replace(
                          /[^0-9]/g,
                          ''
                        )
                      }
                    "
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Address">
                  <el-input v-model="base.userForm.address" size="large" autocomplete="off" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Country">
                  <el-select
                    v-model="base.userForm.country"
                    placeholder="Select"
                    size="large"
                    value-key="country"
                    @change="handleSetStates"
                  >
                    <el-option
                      v-for="item in base.countries"
                      :key="item.country"
                      :label="item.country"
                      :value="item.country"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="State">
                  <el-select v-model="base.userForm.state" placeholder="Select" size="large">
                    <el-option
                      v-for="item in base.states"
                      :key="item"
                      :label="item"
                      :value="item"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="City">
                  <el-input v-model="base.userForm.city" size="large" autocomplete="off" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="ZIP Code">
                  <el-input
                    v-model="base.userForm.zipCode"
                    maxlength="8"
                    size="large"
                    autocomplete="off"
                    @input="
                      () => {
                        base.userForm.zipCode = base.userForm.zipCode.replace(/[^0-9]/g, '')
                      }
                    "
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <el-row justify="end" :gutter="20">
            <el-col v-if="base.activeFormIndex === 1" :span="4">
              <el-button class="w-100" @click="base.activeFormIndex -= 1">
                {{ 'Previous' }}
              </el-button>
            </el-col>
            <el-col :span="4">
              <el-button class="w-100" type="primary" @click="handleNext">
                {{ base.activeFormIndex === 1 ? 'Submit' : 'Next' }}
              </el-button>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <footer class="landing-page-footer">
      <p>Contact us at: info@ZataStays.com</p>
      <p>&copy; 2024 ZT Property Management System. All rights reserved.</p>
    </footer>
  </el-container>
</template>

<script>
import { onBeforeMount, onBeforeUnmount, reactive } from 'vue'
import { ElNotification } from 'element-plus'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { TrendCharts, Calendar, CreditCard, UserFilled, KnifeFork } from '@element-plus/icons-vue'
import _ from 'lodash'

import AuthSvc from '@/services/auth'
import common from '@/utils/common'
import constants from '@/constants'

export default {
  name: 'LandingPage',
  components: {
    TrendCharts,
    Calendar,
    CreditCard,
    UserFilled,
    KnifeFork
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const base = reactive({
      currentTab: 'home',
      email: '',
      password: '',
      activeFormIndex: 0,
      userForm: {
        email: 'testuser1@gmail.com',
        password: 'Welcome@123',
        title: 'Mr.',
        firstName: 'Adam',
        lastName: 'Gilchrist',
        middleName: '',
        gender: 'male',
        roles: ['Superuser'],
        defaultRole: 'Superuser',
        mobileNumber: '1234567890',
        alternateNumber: '1234567890',
        address: 'Kilpauk',
        city: 'Chennai',
        state: 'Tamil Nadu',
        country: 'India',
        zipCode: '600010',
        isPlatformAdministrator: false
      },
      hotelForm: {
        name: 'Test Hotel 1',
        emailId: 'owner@gmail.com',
        mobile: '9999999999',
        website: 'www.google.com',
        address: 'Hornsey Rd, London N7 7AJ, UK',
        checkInTime: '',
        checkOutTime: ''
      },
      titleOptions: constants.TITLE_OPTIONS,
      genderOptions: constants.GENDER_OPTIONS,
      countries: constants.COUNTRIES,
      states: [],
      plans: ['Monthly', 'Quaterly', 'Half Yearly', 'Annually']
    })
    const handleLogin = () => {
      const payload = {
        login: base.email,
        password: base.password
      }
      AuthSvc.login(payload)
        .then(() => {
          router.push('/dashboard')
        })
        .catch(error => {
          console.log('Sign in handleLogin error => ', error)
          common.showErrors(error)
        })
    }
    const handleSetStates = () => {
      if (base.userForm.country) {
        const country = constants.COUNTRIES.find(f => f.country === base.userForm.country)
        base.states = country?.states || []
      } else {
        base.states = []
      }
    }

    const handleNext = () => {
      if (base.activeFormIndex === 0) {
        base.activeFormIndex = 1
      } else {
        const payload = {
          subscriptionName: _.camelCase(base.hotelForm.name),
          plan: {
            id: 'plan1',
            code: 'PlanA',
            name: 'Plan A',
            amount: 0
          },
          tenant: base.hotelForm,
          user: base.userForm
        }
        payload.tenant.code = payload.subscriptionName
        payload.user.login = payload.user.email
        AuthSvc.signup(payload)
          .then(res => {
            console.log('Signed up successfully', res.data)
            ElNotification({
              message: 'User Registered successfully. Please sign in to continue!',
              type: 'success'
            })
            router.push('/home')
          })
          .catch(error => {
            console.log('Error signup user', error)
            common.showErrors(error)
          })
      }
    }
    onBeforeMount(() => {
      store.state.hideConfigButton = true
      store.state.showNavbar = false
      store.state.showSidenav = false
      store.state.showFooter = false
    })
    onBeforeUnmount(() => {
      store.state.hideConfigButton = false
      store.state.showNavbar = true
      store.state.showSidenav = true
      store.state.showFooter = true
    })
    return {
      base,
      handleLogin,
      handleSetStates,
      handleNext
    }
  }
}
</script>

<style>
:root {
  --primary: #00a3ff;
  --light-primary: #ebf8ff;
  --dark: #000000;
  --light: #ffffff;
  --gray: #f5f5f5;
}
.landing-page {
  background: #f8fafc !important;
}
.landing-nav {
  padding: 5px 20px !important;
  position: fixed;
  /* top: 0; */
  width: 100%;
  background: var(--light);
  padding: 1rem 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.nav-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--primary);
}

.logo svg {
  width: 40px;
  height: 40px;
}

.nav-links {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.nav-links a {
  text-decoration: none;
  color: var(--dark);
}

.landing-btn {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.3s ease;
}

.landing-btn-outline {
  border: 2px solid var(--primary);
  color: var(--primary);
  background: transparent;
}

.landing-btn-primary {
  background: var(--primary);
  color: var(--light);
  border: none;
}

.hero {
  padding: 7rem 0rem 4rem;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.hero-content h1 {
  font-size: 2.5rem !important;
  margin-bottom: 1.5rem;
  color: var(--dark);
}

.hero-buttons {
  display: flex;
  gap: 1rem;
}

.hero-image img {
  width: 100%;
  max-width: 600px;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.features {
  padding: 4rem 2rem;
  background: var(--light-primary);
  margin: 0 auto;
  max-width: 1200px;
  align-items: center;
}

/* .features-container {
  max-width: 1200px;
  margin: 0 auto;
} */

.features-header {
  text-align: center;
  margin-bottom: 3rem;
}

.features-header h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: var(--dark);
}

.features-header span {
  color: var(--primary);
}

.features-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  align-items: center;
}

.dashboard-preview {
  background: var(--light);
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.dashboard-preview img {
  width: 100%;
  border-radius: 0.5rem;
}

.feature-item {
  text-align: center;
}

.feature-list-item {
  margin-bottom: 20px;
}

.feature-list-content {
  display: flex !important;
  align-items: center;
  padding-left: 10px;
  flex-wrap: wrap;
}

.feature-icon-box {
  padding: 8px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.feature-icon svg {
  color: var(--primary);
}

.testimonials {
  background: var(--light) !important;
  padding: 4rem 0rem 4rem;
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
}

.testimonials h3 {
  margin-bottom: 3rem;
}

.testimonial-card {
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.testimonial-card p {
  font-style: italic;
  margin-bottom: 1rem;
  color: #444;
}

.testimonial-card .author {
  font-weight: bold;
  color: var(--primary-blue);
}

.landing-page-footer {
  background: var(--dark);
  color: white;
  padding: 2rem 5%;
  text-align: center;
}

.login-button {
  font-weight: 600 !important;
  padding: 1rem 2rem !important;
}

.login-content {
  margin-top: 4rem;
  padding: 2rem;
  display: flex !important;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 5rem);
}

.login-container {
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow:
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  width: 100%;
  max-width: 400px;
}

.login-header {
  text-align: center;
  margin-bottom: 2rem;
}

.login-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 0.5rem;
}

.login-subtitle {
  color: #6b7280;
  font-size: 0.875rem;
}

.form-label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #374151;
}

.form-footer {
  margin-top: 1.5rem;
  text-align: center;
  font-size: 0.875rem;
  color: #6b7280;
}

.primary-color {
  color: var(--primary);
  text-decoration: none;
  font-weight: 600;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: #333 !important;
  transition: background-color 5000s ease-in-out 0s;
}

.el-input__inner:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

@media (min-width: 1400px) {
  .login-container {
    max-width: 1320px;
  }
}

@media (min-width: 1200px) {
  .login-container {
    max-width: 1140px;
  }
}
@media (min-width: 992px) {
  .login-container {
    max-width: 960px;
  }
}
@media (min-width: 768px) {
  .login-container {
    max-width: 720px;
  }
}
@media (min-width: 576px) {
  .login-container {
    max-width: 540px;
  }
}

/* Signup Style Start */
.signup-content {
  margin-top: 4rem;
  padding: 2rem;
  display: flex !important;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 5rem);
}
.signup-container {
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow:
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  width: 100%;
  max-width: 1200px;
}
.signup-header {
  text-align: center;
  margin-bottom: 2rem;
}
.signup-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 0.5rem;
}
.signup-subtitle {
  color: #6b7280;
  font-size: 0.875rem;
}
/* Signup Style End */
</style>
