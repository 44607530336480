<template>
  <div v-loading.fullscreen.lock="base.loading" class="card">
    <div class="card-header px-2 pb-0">
      <el-row>
        <el-col :span="12">
          <el-input
            v-model="base.search.text"
            placeholder="Please input"
            clearable
            class="input-with-select"
            @keyup.enter="onSearch"
          >
            <template #prepend>
              <el-select v-model="base.search.key" placeholder="Select" style="width: 150px">
                <el-option label="Invoice Number" value="invoiceNumber" />
              </el-select>
            </template>
            <template #append>
              <el-button :icon="Search" @click="onSearch" />
            </template>
          </el-input>
        </el-col>
        <el-col :span="12" class="pr20">
          <el-button
            class="text-uppercase ms-auto fr"
            type="primary"
            :icon="Plus"
            @click="handleAddInvoice"
          >
            Invoice
          </el-button>
        </el-col>
      </el-row>
    </div>
    <div class="card-body px-0 pt-0 pb-2 mt-2">
      <el-table
        ref="tableRef"
        class="fnbinvoicestable-max-height"
        row-key="email"
        header-cell-class-name="text-uppercase text-secondary text-xs font-weight-bolder opacity-7"
        row-class-name="text-secondary text-xs font-weight-bold"
        :data="base.data.records"
        :stripe="true"
        :scrollbar-always-on="true"
        style="width: 100%"
      >
        <el-table-column prop="invoiceNumber" label="Invoice Number" />
        <el-table-column prop="totalPrice" label="Total Price" />
        <el-table-column label="">
          <template #default="scope">
            <el-button link type="primary" size="small" @click="handleEditClick(scope.row)">
              Edit
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="">
          <template #default="scope">
            <el-button link type="primary" size="small" @click="handleGenerateInvoice(scope.row)">
              Generate Invoice
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="card-body px-0 pt-0 pb-2 mt-2">
      <el-pagination
        :current-page="base.data.cursor.currentPage"
        small
        class="m-t-10 mb2 ml10"
        layout="total, sizes, prev, pager, next, jumper"
        :page-size="base.data.cursor.perPage"
        :page-sizes="base.pageSizes"
        :total="parseInt(base.data.cursor.totalRecords)"
        @size-change="handlePageSizeChange"
        @current-change="handleCurrentPageChange"
      ></el-pagination>
    </div>
    <el-dialog
      v-model="base.invoiceFormVisible"
      title="FNB Invoice"
      destroy-on-close
      align-center
      fullscreen
    >
      <el-form
        ref="menuItemRef"
        :model="base.menuItemForm"
        label-position="top"
        :rules="base.menuItemRules"
      >
        <el-row class="mt-3 mb-0" :gutter="20">
          <el-col :span="6">
            <el-form-item label="Category" prop="category">
              <el-select
                v-model="base.menuItemForm.category"
                placeholder="Select Category"
                size="large"
                @change="handleCategoryChange"
              >
                <el-option
                  v-for="item in base.categories"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="Menu Item" prop="name">
              <el-select
                v-model="base.menuItemForm.name"
                placeholder="Select Menu Item"
                size="large"
                value-key="name"
                @change="handleMenuItemChange"
              >
                <el-option
                  v-for="item in base.selectedMenuItems"
                  :key="item.name"
                  :label="item.name"
                  :value="item.name"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="Quantity" prop="quantity">
              <el-input-number
                v-model="base.menuItemForm.quantity"
                :min="1"
                class="w-100"
                controls-position="right"
                size="large"
                @change="handleQuantityChange"
              />
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="Price">
              <el-input-number
                v-model="base.menuItemForm.price"
                disabled
                class="w-100"
                size="large"
                :controls="false"
              />
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <el-form-item class="mt30" label="">
              <el-button :icon="Plus" size="large" @click="handleAddMenuItemClick" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <hr class="horizontal dark" />
      <el-table
        ref="tableRef"
        class="items-table-max-height"
        row-key="email"
        header-cell-class-name="text-uppercase text-secondary text-xs font-weight-bolder opacity-7"
        row-class-name="text-secondary text-xs font-weight-bold"
        :data="base.invoiceForm.items"
        style="width: 100%"
      >
        <el-table-column prop="category" label="Category" />
        <el-table-column prop="name" label="Menu Item" />
        <el-table-column prop="itemPrice" label="Default Price" />
        <el-table-column prop="quantity" label="Quantity" />
        <el-table-column prop="price" label="Total Price" />
        <el-table-column label="" min-width="75">
          <template #default="scope">
            <el-button link type="primary" size="small" @click="handleMenuItemEditClick(scope.row)">
              Edit
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <template #footer>
        <el-row class="dialog-footer">
          <el-col :span="6" class="tl">
            <el-form label-width="auto">
              <el-form-item label="Total Price">
                <el-input-number
                  v-model="base.invoiceForm.totalPrice"
                  disabled
                  class="w-100"
                  :controls="false"
                />
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :span="18">
            <el-button @click="base.invoiceFormVisible = false">Cancel</el-button>
            <el-button type="primary" @click="handleSave">Save</el-button>
          </el-col>
        </el-row>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { Search, Plus } from '@element-plus/icons-vue'
import _ from 'lodash'
import FNBInvoiceSvc from '@/services/fnbInvoice'
import MenuItemSvc from '@/services/menuItem'
import AuthSvc from '@/services/auth'
import common from '@/utils/common'
import { v4 as uuidv4 } from 'uuid'
import constants from '@/constants'
import { ElNotification } from 'element-plus'

export default {
  name: 'FNBInvoicesTable',
  setup() {
    const menuItemRef = ref(null)
    const base = reactive({
      loading: false,
      invoiceFormVisible: false,
      search: {
        text: '',
        key: 'invoiceNumber'
      },
      menuItemForm: {},
      invoiceForm: {},
      data: {
        records: [],
        cursor: { currentPage: 0, perPage: 0, totalRecords: 0 }
      },
      pageSizes: constants.DEFAULT_PAGE_SIZES,
      menuItems: [],
      selectedMenuItems: [],
      menuItemRules: {
        category: [{ required: true, message: ' ', trigger: 'blur' }],
        name: [{ required: true, message: ' ', trigger: 'blur' }],
        quantity: [{ required: true, message: ' ', trigger: 'blur' }]
      }
    })

    const local = {
      currentQuery: undefined,
      sort: {},
      fields: undefined
    }

    const onSearch = () => {
      let q = local.currentQuery
      if (base.search.text) {
        q = {}
        local.currentPage = 1
        // if (searchKeyType === 'date') {
        //   q[searchKey] = {
        //     $gte: QueryBuilder.getDate(searchText, 1),
        //     $lt: QueryBuilder.getDate(searchText, 2)
        //   }
        // } else {
        //   q[searchKey] = { $regex: '.*' + searchText + '.*', $options: 'i' }
        // }
        q[base.search.key] = {
          $regex: '.*' + base.search.text + '.*',
          $options: 'i'
        }
        if (base.search.key === 'invoiceNumber') {
          const value = base.search.text.split('-')
          const searchText = value?.[1] ?? base.search.text
          q[base.search.key] = parseInt(searchText)
        }
      } else {
        q = {}
      }
      if (!_.isEqual(q, local.currentQuery)) {
        local.currentQuery = q
        fetchFNBInvoices(
          local.currentQuery,
          local.fields,
          base.data.cursor.currentPage,
          base.data.cursor.perPage,
          local.sort
        )
      }
    }

    onMounted(() => {
      fetchFNBInvoices()
      fetchMenuItems()
    })

    const fetchFNBInvoices = (query, projection, page, limit, sort) => {
      const options = AuthSvc.getOptions()
      FNBInvoiceSvc.find(options, query, projection, page, limit, sort)
        .then(response => {
          base.data = response.data
        })
        .catch(error => {
          console.log('FNBInvoicesTable fetch error => ', error)
          common.showErrors(error)
        })
    }

    const handleSave = () => {
      const options = AuthSvc.getOptions()
      FNBInvoiceSvc.save(options, base.invoiceForm)
        .then(() => {
          base.invoiceFormVisible = false
          fetchFNBInvoices()
        })
        .catch(error => {
          console.log('FNBInvoicesTable handleSave error => ', error)
          common.showErrors(error)
        })
    }

    const fetchMenuItems = () => {
      const options = AuthSvc.getOptions()
      MenuItemSvc.find(options, undefined, undefined, 1, 1000)
        .then(response => {
          base.menuItems = response.data.records
          base.categories = _.uniqBy(base.menuItems, 'category').map(item => item.category)
          base.selectedMenuItems = _.clone(base.menuItems)
        })
        .catch(error => {
          console.log('FNBInvoicesTable fetchMenuItems error => ', error)
          common.showErrors(error)
        })
    }

    const handleCreateNewMenuItem = () => {
      return {
        id: '',
        category: '',
        name: '',
        quantity: 1,
        price: 0,
        itemPrice: 0,
        taxPercentage: 0
      }
    }

    const handleCreateNewInvoice = () => {
      return {
        items: [],
        totalPrice: 0
      }
    }

    const handleAddInvoice = () => {
      base.menuItemForm = handleCreateNewMenuItem()
      base.invoiceForm = handleCreateNewInvoice()
      base.invoiceFormVisible = true
    }

    const handleEditClick = fnbInvoice => {
      const options = AuthSvc.getOptions()
      FNBInvoiceSvc.findById(options, fnbInvoice._id)
        .then(response => {
          base.invoiceForm = response.data
          base.invoiceFormVisible = true
        })
        .catch(error => {
          console.log('FNBInvoicesTable handleEditClick error => ', error)
          common.showErrors(error)
        })
    }

    const handleCategoryChange = () => {
      if (base.menuItemForm.category) {
        base.selectedMenuItems = base.menuItems.filter(
          item => item.category === base.menuItemForm.category
        )
      } else {
        base.selectedMenuItems = _.clone(base.menuItems)
      }
    }

    const handleQuantityChange = () => {
      base.menuItemForm.price = base.menuItemForm.itemPrice * base.menuItemForm.quantity
    }

    const handleMenuItemChange = val => {
      const menuItem = base.selectedMenuItems.find(item => item.name === val)
      base.menuItemForm.itemPrice = _.clone(menuItem.price.default)
      base.menuItemForm.taxPercentage = _.clone(menuItem.taxPercentage)
      handleQuantityChange()
    }

    const handleAddMenuItemClick = () => {
      menuItemRef.value.validate(valid => {
        if (valid) {
          if (base.menuItemForm.id) {
            const menuItemIndex = base.invoiceForm.items.findIndex(
              item => item.id === base.menuItemForm.id
            )
            if (menuItemIndex !== -1) {
              base.invoiceForm.items.splice(menuItemIndex, 1, _.clone(base.menuItemForm))
            }
          } else {
            base.menuItemForm.id = uuidv4()
            base.invoiceForm.items.push(_.clone(base.menuItemForm))
          }
          base.menuItemForm = handleCreateNewMenuItem()
        } else {
          common.showMandatoryValidation()
        }
      })
    }

    const handleCurrentPageChange = val => {
      base.data.cursor.currentPage = val
      fetchFNBInvoices(
        local.currentQuery,
        local.fields,
        base.data.cursor.currentPage,
        base.data.cursor.perPage,
        local.sort
      )
    }

    const handlePageSizeChange = val => {
      base.data.cursor.perPage = val
      fetchFNBInvoices(
        local.currentQuery,
        local.fields,
        base.data.cursor.currentPage,
        base.data.cursor.perPage,
        local.sort
      )
    }

    const handleGenerateInvoice = row => {
      base.loading = true
      const options = AuthSvc.getOptions()
      FNBInvoiceSvc.generate(options, row._id)
        .then(res => {
          base.loading = false
          const url = window.URL.createObjectURL(res.data)
          const link = document.createElement('a')
          link.href = url
          link.download = `${row.invoiceNumber}.pdf`
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          window.URL.revokeObjectURL(url)
          ElNotification({
            type: 'success',
            message: 'Invoice generated successfully!'
          })
        })
        .catch(error => {
          base.loading = false
          console.log('DNBInvoicesTable handleGenerateInvoice error => ', error)
          common.showErrors(error)
        })
    }

    const handleMenuItemEditClick = row => {
      console.log('==>', row)
      console.log('base.invoiceForm.items', base.invoiceForm.items)
      const menuItem = _.cloneDeep(base.invoiceForm.items.find(item => item.id === row.id))
      if (menuItem) {
        base.menuItemForm = menuItem
      }
    }

    return {
      base,
      menuItemRef,
      Search,
      Plus,
      handleEditClick,
      handleAddInvoice,
      handleSave,
      handleCategoryChange,
      handleMenuItemChange,
      handleQuantityChange,
      handleAddMenuItemClick,
      handleCurrentPageChange,
      handlePageSizeChange,
      onSearch,
      handleGenerateInvoice,
      handleMenuItemEditClick
    }
  }
}
</script>

<style>
.fnbinvoicestable-max-height {
  height: calc(100vh - 205px) !important;
}
.items-table-max-height {
  min-height: calc(100vh - 286px);
  max-height: calc(100vh - 286px);
}
</style>
