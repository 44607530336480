<template>
  <el-row>
    <el-col>
      <el-row class="message">Click on an available room to proceed with your booking.</el-row>
      <div v-for="floor in base.floors" :key="floor" class="floor">
        <div class="floor-title">Floor {{ floor }}</div>
        <div class="room-layout">
          <div
            v-for="room in base.rooms[floor]"
            :key="room.roomNumber"
            :class="room.isBooked ? 'room unavailable' : 'room'"
            @click="handleCardRoomClick(room)"
          >
            {{ room.roomNumber }}
          </div>
          <!-- <div class="room unavailable">2</div> -->
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import { reactive, onMounted, watch } from 'vue'
import _ from 'lodash'
import AuthSvc from '@/services/auth'
import ReservationSvc from '@/services/reservation'
import RoomSvc from '@/services/room'
import common from '@/utils/common'
import calendarUtil from '@/utils/calendarUtil'

export default {
  name: 'ReservationsCardView',
  props: {
    selectedDate: {
      default: calendarUtil.getTodaysDate()
    }
  },
  setup(props, { emit }) {
    const base = reactive({
      todaysReservations: [],
      rooms: {},
      floors: []
    })

    watch(
      () => props.selectedDate,
      () => {
        fetchReservations()
      }
    )

    const fetchReservations = () => {
      const options = AuthSvc.getOptions()
      const todaysDate = props.selectedDate
      console.log('todaysDate', todaysDate)
      const query = {
        isCheckedOut: false,
        checkIn: calendarUtil.getDateQuery(todaysDate)
      }
      console.log('query', query)
      ReservationSvc.find(options, query, undefined, 1, 1000)
        .then(response => {
          base.todaysReservations = response.data.records
          fetchRooms()
          console.log('base.reservations', response.data.records)
        })
        .catch(error => {
          console.log('ReservationsTable fetchReservations error => ', error)
          common.showErrors(error)
        })
    }

    const fetchRooms = () => {
      const options = AuthSvc.getOptions()
      RoomSvc.find(options, undefined, undefined, 1, 10000)
        .then(response => {
          let rooms = _.orderBy(response.data.records, 'roomNumber')
          rooms = rooms.map(room => {
            const reservation = base.todaysReservations.find(r => r.room.roomId === room._id)
            room.isBooked = reservation ? true : false
            room.invoice = reservation
            return room
          })
          rooms = _.groupBy(rooms, 'floor')
          base.rooms = rooms
          base.floors = Object.keys(base.rooms)
          console.log('base.rooms grouped', base.rooms)
          console.log('base.todaysReservations', base.todaysReservations)
        })
        .catch(error => {
          console.log('ReservationsTable fetchRooms error => ', error)
          common.showErrors(error)
        })
    }

    const handleCardRoomClick = room => {
      emit('cardRoomClick', room)
    }

    onMounted(() => {
      fetchReservations()
    })

    return {
      base,
      handleCardRoomClick,
      fetchReservations
    }
  }
}
</script>

<style>
.message {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  color: white;
  padding: 5px 10px 5px 10px;
  border-radius: 10px;
  margin-bottom: 15px;
  text-align: center;
  font-weight: bold;
}
.floor {
  margin-bottom: 15px;
}
.floor-title {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  color: white;
  padding: 2px 10px 2px 10px;
  border-radius: 10px 10px 0 0;
  font-weight: bold;
}
.room-layout {
  display: grid;
  grid-template-columns: repeat(15, 1fr);
  gap: 10px;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 0 0 10px 10px;
}
.room {
  aspect-ratio: 1;
  border: 2px solid #4caf50;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: bold;
  background-color: #e8f5e9;
  color: #4caf50;
}
.room:hover {
  transform: scale(1.1);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}
.room.unavailable {
  background-color: #ffebee;
  border-color: #ef5350;
  color: #ef5350;
  cursor: not-allowed;
}
.room.selected {
  background-color: #1976d2;
  color: white;
  border-color: #1565c0;
}
</style>
