<template>
  <div class="card">
    <div class="card-header px-2 pb-0">
      <el-row>
        <el-col :span="12">
          <el-input
            v-model="base.search.text"
            placeholder="Please input"
            clearable
            class="input-with-select"
            @keyup.enter="onSearch"
          >
            <template #prepend>
              <el-select v-model="base.search.key" placeholder="Select" style="width: 125px">
                <el-option label="Category" value="category" />
                <el-option label="Name" value="name" />
              </el-select>
            </template>
            <template #append>
              <el-button :icon="Search" @click="onSearch" />
            </template>
          </el-input>
        </el-col>
        <el-col :span="12" class="pr20">
          <el-button
            class="text-uppercase ms-auto fr"
            type="primary"
            :icon="Plus"
            @click="handleAddMenuItem"
          >
            Menu Item
          </el-button>
        </el-col>
      </el-row>
    </div>
    <div class="card-body px-0 pt-0 pb-2 mt-2">
      <el-table
        ref="tableRef"
        class="menuitemstable-max-height"
        row-key="email"
        header-cell-class-name="text-uppercase text-secondary text-xs font-weight-bolder opacity-7"
        row-class-name="text-secondary text-xs font-weight-bold"
        :data="base.data.records"
        :stripe="true"
        :scrollbar-always-on="true"
        style="width: 100%"
      >
        <el-table-column prop="category" label="Category" />
        <el-table-column prop="name" label="Name" />
        <el-table-column prop="price.default" label="Price" />
        <el-table-column prop="taxPercentage" label="Tax Percentage" />
        <el-table-column prop="isActive" label="Is Active?" width="150">
          <template #default="scope">
            <span v-if="scope.row.isActive" class="badge bg-gradient-success">Yes</span>
            <span v-else class="badge bg-gradient-secondary">No</span>
          </template>
        </el-table-column>
        <el-table-column label="" min-width="75">
          <template #default="scope">
            <el-button link type="primary" size="small" @click="handleEditClick(scope.row)">
              Edit
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="card-body px-0 pt-0 pb-2 mt-2">
      <el-pagination
        :current-page="base.data.cursor.currentPage"
        small
        class="m-t-10 mb2 ml10"
        layout="total, sizes, prev, pager, next, jumper"
        :page-size="base.data.cursor.perPage"
        :page-sizes="base.pageSizes"
        :total="parseInt(base.data.cursor.totalRecords)"
        @size-change="handlePageSizeChange"
        @current-change="handleCurrentPageChange"
      ></el-pagination>
    </div>
    <el-dialog
      v-model="base.menuItemFormVisible"
      title="Menu Item Information"
      width="70%"
      destroy-on-close
      align-center
    >
      <el-form
        ref="menuItemRef"
        :model="base.menuItemForm"
        label-position="top"
        :rules="base.menuItemRules"
      >
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="Category" prop="category">
              <el-input v-model="base.menuItemForm.category" size="large" autocomplete="off" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Name" prop="name">
              <el-input v-model="base.menuItemForm.name" size="large" autocomplete="off" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Price" prop="price.default">
              <el-input-number
                v-model="base.menuItemForm.price.default"
                class="w-100"
                :controls="false"
                size="large"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Tax Percentage">
              <el-input-number
                v-model="base.menuItemForm.taxPercentage"
                class="w-100"
                :controls="false"
                size="large"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Is Active?">
              <el-switch
                v-model="base.menuItemForm.isActive"
                size="large"
                active-text="Yes"
                inactive-text="No"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="base.menuItemFormVisible = false">Cancel</el-button>
          <el-button type="primary" @click="handleSave">Confirm</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { Search, Plus } from '@element-plus/icons-vue'
import MenuItemSvc from '@/services/menuItem'
import AuthSvc from '@/services/auth'
import common from '@/utils/common'
import { ElNotification } from 'element-plus'
import constants from '@/constants'
import _ from 'lodash'

export default {
  name: 'MenuItemsTable',
  setup() {
    const menuItemRef = ref(null)
    const base = reactive({
      menuItemFormVisible: false,
      search: {
        text: '',
        key: 'name'
      },
      menuItemForm: {
        category: '',
        name: '',
        price: {
          default: 0
        },
        taxPercentage: 5,
        isActive: true
      },
      data: {
        records: [],
        cursor: { currentPage: 0, perPage: 0, totalRecords: 0 }
      },
      pageSizes: constants.DEFAULT_PAGE_SIZES,
      menuItemRules: {
        category: [{ required: true, message: ' ', trigger: 'blur' }],
        name: [{ required: true, message: ' ', trigger: 'blur' }],
        'price.default': [{ required: true, message: ' ', trigger: 'blur' }]
      }
    })

    const local = {
      currentQuery: undefined,
      sort: {},
      fields: undefined
    }

    const onSearch = () => {
      let q = local.currentQuery
      if (base.search.text) {
        q = {}
        local.currentPage = 1
        // if (searchKeyType === 'date') {
        //   q[searchKey] = {
        //     $gte: QueryBuilder.getDate(searchText, 1),
        //     $lt: QueryBuilder.getDate(searchText, 2)
        //   }
        // } else {
        //   q[searchKey] = { $regex: '.*' + searchText + '.*', $options: 'i' }
        // }
        q[base.search.key] = {
          $regex: '.*' + base.search.text + '.*',
          $options: 'i'
        }
      } else {
        q = {}
      }
      if (!_.isEqual(q, local.currentQuery)) {
        local.currentQuery = q
        fetchMenuItems(
          local.currentQuery,
          local.fields,
          base.data.cursor.currentPage,
          base.data.cursor.perPage,
          local.sort
        )
      }
    }

    onMounted(() => {
      fetchMenuItems()
    })

    const fetchMenuItems = (query, projection, page, limit, sort) => {
      const options = AuthSvc.getOptions()
      MenuItemSvc.find(options, query, projection, page, limit, sort)
        .then(response => {
          base.data = response.data
        })
        .catch(error => {
          console.log('MenuItemsTable fetchMenuItems error => ', error)
          common.showErrors(error)
        })
    }

    const createNewMenuItem = () => {
      return {
        category: '',
        name: '',
        price: {
          default: 0
        },
        taxPercentage: 5,
        isActive: true
      }
    }

    const handleAddMenuItem = () => {
      base.menuItemForm = createNewMenuItem()
      base.menuItemFormVisible = true
    }

    const handleEditClick = menuItem => {
      const options = AuthSvc.getOptions()
      MenuItemSvc.findById(options, menuItem._id)
        .then(response => {
          base.menuItemForm = response.data
          base.menuItemFormVisible = true
        })
        .catch(error => {
          console.log('MenuItemsTable handleEditClick error => ', error)
          common.showErrors(error)
        })
    }

    const handleSave = () => {
      menuItemRef.value.validate(valid => {
        if (valid) {
          const options = AuthSvc.getOptions()
          MenuItemSvc.save(options, base.menuItemForm)
            .then(result => {
              const message =
                result.status === 201
                  ? 'Menu Item created successfully'
                  : 'Menu Item updated successfully'
              ElNotification({
                message,
                type: 'success'
              })
              base.menuItemFormVisible = false
              fetchMenuItems()
            })
            .catch(error => {
              console.log('MenuItemsTable handleSave error => ', error)
              common.showErrors(error)
            })
        } else {
          common.showMandatoryValidation()
        }
      })
    }

    const handleCurrentPageChange = val => {
      base.data.cursor.currentPage = val
      fetchMenuItems(
        local.currentQuery,
        local.fields,
        base.data.cursor.currentPage,
        base.data.cursor.perPage,
        local.sort
      )
    }

    const handlePageSizeChange = val => {
      base.data.cursor.perPage = val
      fetchMenuItems(
        local.currentQuery,
        local.fields,
        base.data.cursor.currentPage,
        base.data.cursor.perPage,
        local.sort
      )
    }

    return {
      base,
      menuItemRef,
      Search,
      Plus,
      handleEditClick,
      handleAddMenuItem,
      handleSave,
      handleCurrentPageChange,
      handlePageSizeChange,
      onSearch
    }
  }
}
</script>

<style>
.menuitemstable-max-height {
  height: calc(100vh - 205px) !important;
}
</style>
